<template>
    <div class="countdown-container">
        <template v-if="isDaysMode">
            <p class="countdown days">
                {{ days }} <br />
                {{ days === '1' ? $t('timers.day') : $t('timers.days') }}
            </p>
        </template>
        <template v-else-if="isHoursMode">
            <p class="countdown hours">{{ hours }}:{{ minutes }}:</p>
            <p class="small-text">{{ seconds }}</p>
        </template>
        <template v-else>
            <p class="countdown">{{ minutes }}:{{ seconds }}</p>
        </template>
    </div>
</template>

<script>
export default {
    props: { nextAlarm: Number },
    data() {
        return {
            targetTime: null,
            days: '0',
            hours: '00',
            minutes: '00',
            seconds: '00',
            interval: null,
            isHoursMode: false,
            isDaysMode: false,
            isMoreThanSixHours: null
        }
    },
    computed: {
        getTimeFilter() {
            const config = this.$store.getters['login/getConfig']
            return config.Timersh || config.Timersh > 0 ? config.Timersh : 6
        }
    },
    methods: {
        startCountdown() {
            this.targetTime = new Date(this.nextAlarm)
            this.updateCountdown()
            this.interval = setInterval(() => {
                this.updateCountdown()
            }, 1000)
        },

        updateCountdown() {
            const now = new Date()
            const timeRemaining = this.targetTime - now

            if (timeRemaining <= 0) {
                clearInterval(this.interval)
                this.days = '0'
                this.hours = '00'
                this.minutes = '00'
                this.seconds = '00'
                this.isHoursMode = false
                this.isDaysMode = false
                return
            }

            const totalSeconds = Math.floor(timeRemaining / 1000)
            const totalMinutes = Math.floor(totalSeconds / 60)
            const totalHours = Math.floor(totalMinutes / 60)

            const moreThanSixHours = totalHours >= this.getTimeFilter
            if (this.isMoreThanSixHours !== moreThanSixHours) {
                this.isMoreThanSixHours = moreThanSixHours
                this.emitTimeStatus(moreThanSixHours)
            }

            if (totalHours >= 24) {
                // Redondear hacia arriba solo si superamos las 23:59:59
                const totalDays = Math.ceil(totalHours / 24)
                this.isDaysMode = true
                this.isHoursMode = false
                this.days = String(totalDays)
            } else if (totalHours > 0) {
                // Muestra horas si hay más de 59 minutos y 59 segundos
                this.isDaysMode = false
                this.isHoursMode = true
                this.hours = String(totalHours).padStart(2, '0')
                this.minutes = String(totalMinutes % 60).padStart(2, '0')
                this.seconds = String(totalSeconds % 60).padStart(2, '0')
            } else {
                // Muestra minutos y segundos
                this.isDaysMode = false
                this.isHoursMode = false
                this.minutes = String(totalMinutes).padStart(2, '0')
                this.seconds = String(totalSeconds % 60).padStart(2, '0')
            }
        },

        emitTimeStatus(isMoreThanSixHours) {
            this.$emit('timeStatusChanged', isMoreThanSixHours)
        }
    },
    watch: {
        nextAlarm: {
            handler(newValue) {
                if (newValue) {
                    this.startCountdown()
                }
            },
            deep: true
        }
    },
    mounted() {
        if (this.nextAlarm) {
            this.startCountdown()
        }
    },
    beforeDestroy() {
        clearInterval(this.interval)
    }
}
</script>

<style lang="scss" scoped>
.countdown-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
}

p {
    padding: 0px !important;
}

.countdown {
    font-size: 16px;
    font-family: 'Avenir-Regular';

    @media (min-width: 1025px) {
        font-size: 24px;
    }
}

.hours,
.days {
    font-family: 'Avenir-Regular';
    text-align: center;
}

.small-text {
    font-size: 12px;
    margin-top: 2px;
    font-family: 'Avenir-Regular';

    @media (min-width: 1025px) {
        font-size: 20px;
    }
}
</style>
